<script lang="ts" setup>
import type { Entry } from "contentful";
import {
  isTypeCompany,
  isTypeNetworkMember,
  type TypeCompanySkeleton,
  type TypeNetworkMemberSkeleton,
} from "~/types/contentful";

const props = defineProps<{
  item: Entry<
    TypeCompanySkeleton | TypeNetworkMemberSkeleton,
    "WITHOUT_UNRESOLVABLE_LINKS",
    "en-GB"
  >;
}>();

const logoItemLink = computed(() => {
  const pageSlug = useRoute().params.slug?.[1] ?? "";
  const activeEngagement = isTypeCompany(props.item)
    ? props.item.fields.projects?.find(
      (project) => project?.fields.slug === pageSlug && project.fields.active,
    )
    : false;
  const activeEngagementUrl = activeEngagement ? "/" + pageSlug : "";
  const assessedCompany = !(
    pageSlug === "waste-pollution"
    && isTypeCompany(props.item)
    && ["yara-international-asa", "darling-ingredients-inc"].includes(
      props.item.fields.slug,
    )
  );

  if (isTypeCompany(props.item))
    return getUrl(props.item) + (assessedCompany ? activeEngagementUrl : "");
  if (isTypeNetworkMember(props.item)) return props.item.fields.url;

  return undefined;
});
</script>

<template>
  <div class="card-block-logo-item">
    <NuxtLink :to="logoItemLink">
      <NuxtImg
        v-if="item.fields.logo?.fields.file"
        class="rounded-sm border border-ui-grey2 px-4 py-3"
        :src="item.fields.logo.fields.file.url"
        :alt="item.fields.logo.fields.title"
        :width="356"
        :height="213"
        fit="contain"
        loading="lazy"
      />
      <h4 class="my-2 leading-[1.2]">
        {{ item.fields.name }}
      </h4>
    </NuxtLink>
  </div>
</template>
