import type { ChainModifiers, Entry, EntryFieldTypes, EntrySkeletonType, LocaleCode } from "contentful";

export type TypeNetworkMemberFields = {
  name?: EntryFieldTypes.Symbol;
  aum?: EntryFieldTypes.Number;
  joinDate?: EntryFieldTypes.Date;
  hq?: EntryFieldTypes.Symbol;
  url?: EntryFieldTypes.Symbol;
  logo?: EntryFieldTypes.AssetLink;
};

export type TypeNetworkMemberSkeleton = EntrySkeletonType<TypeNetworkMemberFields, "networkMember">;
export type TypeNetworkMember<Modifiers extends ChainModifiers, Locales extends LocaleCode = LocaleCode> = Entry<TypeNetworkMemberSkeleton, Modifiers, Locales>;

export function isTypeNetworkMember<Modifiers extends ChainModifiers, Locales extends LocaleCode>(entry: Entry<EntrySkeletonType, Modifiers, Locales>): entry is TypeNetworkMember<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === "networkMember";
}
